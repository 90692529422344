import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const theme = createTheme({
  direction: 'rtl'
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

async function loginUser(credentials) {
  //const loginUri = 'https://localhost:44332/account/login'; //DEV
  //const loginUri = 'https://cors-everywhere-me.herokuapp.com/http://lawyers-assistant-api.us-east-1.elasticbeanstalk.com/account/login'; //PROD
  //const loginUri = 'https://cors-everywhere-me.herokuapp.com/https://private-api-c4d2b6.nettcase.com/account/login'; //PROD
  const loginUri = 'https://private-api-c4d2b6.nettcase.com/account/login'; //PROD

  return fetch(loginUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showloginsuccessalert, setLoginSuccessOpen] = React.useState(false);
  const [showloginfailurealert, setLoginFailureOpen] = React.useState(false);

  // const handleEnter = (event) => {
  //   if (event.keyCode === 13)
  //       // var d = new Date();
  //       console.log("Enter ");
  // };

  // window.addEventListener('keypress', handleEnter);

  const handleSubmit = async e => {
    e.preventDefault();
    const loginResponse = await loginUser({
      username,
      password
    });

    if (!loginResponse.token) {
      setLoginFailureOpen(true);
    }
    else {
      setLoginSuccessOpen(true); 
    }

    window.setTimeout(
      () => { setToken(loginResponse.token);
              window.location.href = window.location.origin },
      3000
    );
  }

  const handleLoginSuccessClose = async e => {
    setLoginSuccessOpen(false); 
  }

  const handleLoginFailureClose = async e => {
    setLoginFailureOpen(false); 
  }

  return(
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
      <div className="login-wrapper">
        <h2>התחברות</h2>
        <p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TextField
                required
                id="outlined-required"
                label='אימייל'
                onChange={e => setUserName(e.target.value)} 
            />
          </LocalizationProvider>
        </p>
        <p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TextField
                required
                label="סיסמה"
                type="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
            />
          </LocalizationProvider>
        </p>
        <div className='loginButtonContainer'>
          <Button variant="contained" onClick={handleSubmit}>התחבר</Button>
        </div>
      {/* </form> */}
        <p>
          <span>אין לך משתמש? <a href='/registration'>הרשם\י</a></span>
        </p>
      </div>
      <Snackbar open={showloginsuccessalert} autoHideDuration={2000} onClose={handleLoginSuccessClose}>
        <Alert variant="filled" severity="success" sx={{ width: '100%' }}>ברוכים השבים! מעבירים אתכם לסימולטור</Alert>
      </Snackbar>
      <Snackbar open={showloginfailurealert} autoHideDuration={3000} onClose={handleLoginFailureClose}>
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>בעיה בהתחברות, אנא נסה\י שנית</Alert>
      </Snackbar>
      </ThemeProvider>
    </CacheProvider>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}