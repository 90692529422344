import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';

const theme = createTheme({
    direction: 'rtl'
  });
  
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//   });

export default function Test({ setToken }) {
    const [open, setOpen] = React.useState(false);

    const handleClick = async e => {
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    // const action = (
    //     <React.Fragment>
    //       <Button color="secondary" size="small" onClick={handleClose}>
    //         UNDO
    //       </Button>
    //       <IconButton
    //         size="small"
    //         aria-label="close"
    //         color="inherit"
    //         onClick={handleClose}
    //       >
    //         <CloseIcon fontSize="small" />
    //       </IconButton>
    //     </React.Fragment>
    // );

    return(
        <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
        <div>
            <h2>TEST</h2>
            <Button variant="contained" onClick={handleClick}>TEST</Button>
            {/* <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="Note archived"
      /> */}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>ברוכים הבאים! מיד מעבירים אתכם לסימולטור</Alert>
        {/* <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>בעיה בהתחברות, אנא נסה\י שנית</Alert> */}
      </Snackbar>
        </div>
        </ThemeProvider></CacheProvider>
    )
  }