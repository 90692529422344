import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './EventSimulator.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactGA from 'react-ga';

const theme = createTheme({
  direction: 'rtl'
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// function RTL(props) {
//   return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
// }

export default function EventSimulator({ getToken }) {
    const [last_pleading_date, setLastPleadingDate] = useState();
    const [pre_trial_date, setPreTrialDate] = useState();
    const [show_invalid_dates_alert, setInvalidDatesAlertOpen] = useState(false);

    const [usage_terms_approved, setUsageTermsApproved] = useState(false);
    const [show_approve_usage_terms_alert, setUsageTermsNotApprovedAlert] = useState(false);
    const [show_general_error_alert, setGeneralErrorAlert] = useState(false);
    
    //const [case_name, setCaseNameInCalendarEvents] = useState('');

    const getLocalDateString = (date) => {
      let offset = date.getTimezoneOffset()
      let local_date = new Date(date.getTime() - (offset*60*1000))
      return local_date.toISOString().split('T')[0]
    }

    const openGoogleCalendarEvent = async (startDate, endDate, description) => {
      if (document.getElementById("case_name").value) {
        description = document.getElementById("case_name").value + ": " + description;
      }
      let url = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(description) + "&dates=" + startDate + "%2F" + endDate + "&details=" + encodeURIComponent(description) + "&sf=true&output=xml";

      window.gtag("event", "Create calendar event", {
        event_category: "Calendar",
        event_label: 'Open event: start_date: ' + startDate + ', end_date: ' + endDate + ', description: ' + description
      });

      window.open(url);
    }

    const handleUsageTermsChange = (event) => {
      setUsageTermsApproved(event.target.checked);
    };

    const handleSubmit = async e => {
      //const eventSimulatorUri = 'https://localhost:44332/eventsimulator/getevents'; //DEV
      //const eventSimulatorUri = 'https://cors-everywhere-me.herokuapp.com/http://lawyers-assistant-api.us-east-1.elasticbeanstalk.com/eventsimulator/getevents'; //PROD
      //const eventSimulatorUri = 'https://cors-everywhere-me.herokuapp.com/https://private-api-c4d2b6.nettcase.com/eventsimulator/getevents'; //PROD
      const eventSimulatorUri = 'https://private-api-c4d2b6.nettcase.com/eventsimulator/getevents'; //PROD

      setGeneralErrorAlert(true);
      return;

      if (!usage_terms_approved) {
        setUsageTermsNotApprovedAlert(true);
        return;
      }
      
      let last_pleading_date_local_date_str = '';
      let pre_trial_date_local_date_str = '';

      try {
        last_pleading_date_local_date_str = getLocalDateString(last_pleading_date);
        pre_trial_date_local_date_str = getLocalDateString(pre_trial_date);
      }
      catch(exception) {
        setInvalidDatesAlertOpen(true);
        return;
      }

      window.gtag("event", "Run simulation", {
        event_category: "Simulator",
        event_label: 'last_pleading: ' + last_pleading_date_local_date_str + ', pre_trial: ' + pre_trial_date_local_date_str
      });

      fetch(eventSimulatorUri + '?lastPleadingDate=' + last_pleading_date_local_date_str + '&preTrialDate=' + pre_trial_date_local_date_str, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + getToken()
          }
        })
        .then(response => response.json())
        .then(data => { 
          console.log(data);
          renderResult(data);
        });
    }
    //https://www.google.com/calendar/render?action=TEMPLATE&text=test&details=ddd&location=sss&dates=20220410T194500Z%2F20220411T183000Z
    const renderResult = async (data) => {

      setUsageTermsApproved(false);
      
      const events = data.map((event) =>
        <tr className='restulsTableTr'>
          <td>{event.description}</td>
          <td>{event.dateString + ' ' + event.dayOfWeekString + ''}</td>
          {/* <td>
            <a href={"https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(event.description) + "&dates=" + event.calendarEventLinkStartDate + "%2F" + event.calendarEventLinkEndDate + "&details=" + event.description + "&sf=true&output=xml"} target="_blank">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/512px-Google_Calendar_icon_%282020%29.svg.png?20201017163428" alt='Google Calendar' className='googleCalendarIcon' / >
            </a>
          </td> */}
          <td>
            <img  onClick={() => { openGoogleCalendarEvent(event.calendarEventLinkStartDate, event.calendarEventLinkEndDate, event.description) }} 
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/512px-Google_Calendar_icon_%282020%29.svg.png?20201017163428" 
                  alt='Google Calendar' className='googleCalendarIcon' / >
          </td>
          <td>{event.notes}</td>
        </tr>
      );

      ReactDOM.render(
        <div className='resultsMainDiv'>
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <TextField  name="case_name"
                          label="שם התיק לאירועי היומן" 
                          variant="standard"
                          //value={case_name}
                          //onChange={ event => setCaseNameInCalendarEvents(event.target.value) }
                          id="case_name" />
            </ThemeProvider>
          </CacheProvider>
          <br />
          <br />
          <table className='restulsTable' cellSpacing="100"><thead><tr className='restulsTableTr'><td>מועד</td><td>תאריך</td><td>הוסף ליומן</td><td>הערות</td></tr></thead><tbody>{events}</tbody></table>
        </div>,
        document.getElementById('simulatedEvents')
      );
    }

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="simulator-wrapper">
            <h2>סימולטור מועדים</h2>
            <p>על פי תקנות סדר הדין האזרחי, התשע"ט-2018</p>
            {/* <p>אין להסתמך על סימולטור המועדים באתר nettcase.com לצורך ביצוע פעולות מסוימות או פעולות כלשהן.<br />
            מטרת הסימולטור היא להוות כלי עזר בנוסף לחישובים ידניים ו\או הצלבת נתונים עם חישובים אחרים.<br />
            אתר nettcase.com אינו אחראי לנזקים ו\או הפסדים העלולים להיגרם כתוצאה מהסתמכות על חישוב שבוצע<br />
            בסימולטור המועדים או בכל כלי המופיע באתר.</p> */}
            <p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker className='datePicker'
                            inputFormat="dd/MM/yyyy"
                            label="כתב הטענות האחרון" 
                            name="last_pleading_date" 
                            value={last_pleading_date} 
                            // onChange={ event => setLastPleadingDate(event.toISOString()) } 
                            onChange={ event => setLastPleadingDate(event) } 
                            renderInput={(params) => <TextField {...params} />} 
                            />
              </LocalizationProvider>
            </p>
            <p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker   className='datePicker'
                              inputFormat="dd/MM/yyyy"
                              label="קדם המשפט הראשון" 
                              name="pre_trial_date"
                              value={pre_trial_date}
                              // onChange={ event => setPreTrialDate(event.toISOString()) }  
                              onChange={ event => setPreTrialDate(event) }  
                              renderInput={(params) => <TextField {...params} />} 
                              />
              </LocalizationProvider>
            </p>
            <p>באישור תנאי השימוש באתר ובסימולטור, המשתמש:</p>
            <ul>
              <li>מאשר כי השימוש בסימולטור ובפיצ'רים הנלווים לו, נעשה על אחריותו הבלעדית (של המשתמש)</li>
              <li>מתחייב לחשב בעצמו ובנפרד את כל המועדים שהאתר חישוב עבורו, למקרה שהחישוב שהוגש לו שגוי, ומאשר כי הוסבר לו שמטרת הסימולטור היא להוות כלי עזר בנוסף לחישובים ידניים או הצלבת נתונים עם חישובים אחרים</li>
              <li>מאשר שנמסר לו, כי החישוב באתר הוא הצעה בלבד של מפעילי האתר, ומבוסס בין היתר על פרשנות של התקנות, שטרם ניתנה לגביה הכרעה בפסיקה ובכלל זאת, באשר לשאלת בואם של ימי פגרה במנייני ימים שונים שנקבעו בתקנות החדשות</li>
              <li>מוותר באופן סופי, מוחלט ובלתי חוזר, על כל טענה נגד האתר, מפעיליו ומי מטעמם, לרבות במקרה של טעות בחישוב שהוגש לו ביחס למועדים, או טעות בהכללתם ביומן המשתמש, ומסכים שהאתר או מפעיליו לא יהיו אחראים לנזקים או הפסדים העלולים להיגרם כתוצאה מהסתמכות על חישוב שבוצע בסימולטור המועדים או בכל כלי המופיע באתר</li>
              <li>מסכים כי האתר, מפעיליו ומי מטעמם, רשאים להפסיק את השימוש באתר או בסימולטור, בכל עת וללא כל הודעה מוקדמת, וכן לגבות תשלום בגין השימוש כאמור</li>
              <li>מאשר כי כל זכויות היוצרים והקניין הרוחני באתר, במחשבון ובשאר התכנים והשירותים שכלולים באתר, שייכים לאתר, למפעיליו ולמי מטעמם בלבד, ומתחייב לא להעתיק דבר הימנם ובכלל זאת רעיונות, שיטות וגרפיקות</li>
              <li>מתחייב לא לעשות כל שימוש באתר, מלבד שימוש אישי שלא למטרת רווח, או לבסס פעילות אחרת כלשהי על פעולת האתר</li>
              <li>מאשר כי סמכות השיפוט הבלעדית בעניין האתר והשימוש בו, תהיה מסורה לבתי המשפט במחוז תל אביב, ושהדין יהיה הדין הישראלי בלבד, ללא תוקף לכללי ברירת דין כלשהם</li>
              <li>מאשר כי הסכמת האתר ומפעיליו לשימוש באתר ובסימולטור, ניתנה כנגד ובכפוף בלבד לאישורים, הוויתורים וההסכמות המפורטות לעיל</li>
              <li>מאשר שנמסר לי כי הסימולטור לא מחשב מועדים מאוחרים ליום 31 בדצמבר 2030</li>
            </ul>
            {/* <ul>
              <li>מאשר כי השימוש בסימולטור ובפיצ'רים הנלווים לו, נעשה על אחריותו הבלעדית (של המשתמש)</li>
              <li>מתחייב לחשב בעצמו ובנפרד את כל המועדים שהאתר חישוב עבורו, למקרה שהחישוב שהוגש לו שגוי,<br />ומאשר כי הוסבר לו שמטרת הסימולטור היא להוות כלי עזר בנוסף לחישובים ידניים או הצלבת נתונים עם חישובים אחרים</li>
              <li>מאשר שנמסר לו, כי החישוב באתר הוא הצעה בלבד של מפעילי האתר, ומבוסס בין היתר על פרשנות של התקנות,<br />שטרם ניתנה לגביה הכרעה בפסיקה ובכלל זאת, באשר לשאלת בואם של ימי פגרה במנייני ימים שונים שנקבעו בתקנות החדשות</li>
              <li>מוותר באופן סופי, מוחלט ובלתי חוזר, על כל טענה נגד האתר, מפעיליו ומי מטעמם, לרבות במקרה של טעות בחישוב שהוגש לו ביחס למועדים, או טעות בהכללתם ביומן המשתמש,<br />ומסכים שהאתר או מפעיליו לא יהיו אחראים לנזקים או הפסדים העלולים להיגרם כתוצאה מהסתמכות על חישוב שבוצע בסימולטור המועדים או בכל כלי המופיע באתר</li>
              <li>מסכים כי האתר, מפעיליו ומי מטעמם, רשאים להפסיק את השימוש באתר או בסימולטור, בכל עת וללא כל הודעה מוקדמת, וכן לגבות תשלום בגין השימוש כאמור</li>
              <li>מאשר כי כל זכויות היוצרים והקניין הרוחני באתר, במחשבון ובשאר התכנים והשירותים שכלולים באתר, שייכים לאתר, למפעיליו ולמי מטעמם בלבד,<br />ומתחייב לא להעתיק דבר הימנם ובכלל זאת רעיונות, שיטות וגרפיקות</li>
              <li>מתחייב לא לעשות כל שימוש באתר, מלבד שימוש אישי שלא למטרת רווח, או לבסס פעילות אחרת כלשהי על פעולת האתר</li>
              <li>מאשר כי סמכות השיפוט הבלעדית בעניין האתר והשימוש בו, תהיה מסורה לבתי המשפט במחוז תל אביב, ושהדין יהיה הדין הישראלי בלבד, ללא תוקף לכללי ברירת דין כלשהם</li>
              <li>מאשר כי הסכמת האתר ומפעיליו לשימוש באתר ובסימולטור, ניתנה כנגד ובכפוף בלבד לאישורים, הוויתורים וההסכמות המפורטות לעיל</li>
            </ul> */}
            <FormControlLabel control={<Checkbox onChange={handleUsageTermsChange} checked={usage_terms_approved} />} label="אני מאשר\ת את תנאי השימוש באתר ובסימולטור" />
            <p>
            <Button variant="contained" onClick={handleSubmit} /*onKeyPress={(e) => {if (e.key === 'Enter') { handleSubmit(); }}}*/>הצג מועדים</Button>
            </p>
            <div id='simulatedEvents'></div>
            <p>להצעות לשיפור / דיווח על באגים - אנא שלחו הודעתכם לכתובת <a href="mailto:netcase2022@gmail.com">netcase2022@gmail.com</a></p>
          </div>
          <Snackbar open={show_invalid_dates_alert} autoHideDuration={2000} onClose={() => { setInvalidDatesAlertOpen(false); }}>
              <Alert variant="filled" severity="error" sx={{ width: '100%' }}>אנא בדקו את תקינות התאריכים</Alert>
          </Snackbar>
          <Snackbar open={show_approve_usage_terms_alert} autoHideDuration={2000} onClose={() => { setUsageTermsNotApprovedAlert(false); }}>
              <Alert variant="filled" severity="error" sx={{ width: '100%' }}>אנא אשרו את תנאי השימוש באתר ובסימולטור</Alert>
          </Snackbar>
          <Snackbar open={show_general_error_alert} autoHideDuration={2000} onClose={() => { setGeneralErrorAlert(false); }}>
              <Alert variant="filled" severity="error" sx={{ width: '100%' }}>שגיאה כללית</Alert>
          </Snackbar>
        </ThemeProvider>
      </CacheProvider>
    );
}