import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token'); //localStorage
        const userToken = JSON.parse(tokenString);
        // return userToken?.token
        return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        sessionStorage.setItem('token', JSON.stringify(userToken)); //localStorage
        // setToken(userToken.token);
        setToken(userToken);
    };

    return {
        token: token,
        setToken: saveToken,
        getToken: getToken
    }
}