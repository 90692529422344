import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Registration.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const theme = createTheme({
    direction: 'rtl'
});
  
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});
  
function RTL(props) {
    return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

async function registerUser(userInfo) {
    //const registrationUri = 'https://localhost:44332/account/register'; //DEV
    //const registrationUri = 'https://cors-everywhere-me.herokuapp.com/http://lawyers-assistant-api.us-east-1.elasticbeanstalk.com/account/register'; //PROD
    //const registrationUri = 'https://cors-everywhere-me.herokuapp.com/https://private-api-c4d2b6.nettcase.com/account/register'; //PROD
    const registrationUri = 'https://private-api-c4d2b6.nettcase.com/account/register'; //PROD

    return fetch(registrationUri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userInfo)
    })
    .then(data => data.json())
}

export default function Registration({ setToken }) {
    const [email, setEmail] = useState();
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [lawofficename, setLawOfficeName] = useState();
    const [password, setPassword] = useState();
    const [passwordConfirmation, setPasswordConfirmation] = useState();
    const [showregistrationsuccessalert, setRegistrationSuccessOpen] = useState(false);
    const [showregistrationfailurealert, setRegistrationFailureOpen] = useState(false);
    const [showinvalidpasswordlengthalert, setInvalidPasswordLengthAlertOpen] = useState(false);
    const [showinvalidpasswordconfirmationalert, setInvalidPasswordConfirmationAlertOpen] = useState(false);

    const validateRegistrationInfo = e => {
      if (password.length < 6) {
        setInvalidPasswordLengthAlertOpen(true);
        return false;
      }

      if (password != passwordConfirmation) {
        setInvalidPasswordConfirmationAlertOpen(true);
        return false;
      }

      return true;
    }

    const handleSubmit = async e => {
      if (!validateRegistrationInfo()) {
          return false;
      }

      const registrationResponse = await registerUser({
        email,
        firstname,
        lastname,
        lawofficename,
        password
      });

      if (!registrationResponse.token) {
        setRegistrationFailureOpen(true);
      }
      else {
        setRegistrationSuccessOpen(true);
        window.setTimeout(
          () => { setToken(registrationResponse.token);
                  window.location.href = window.location.origin; },
          3000
        );
      }
    }

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
        <div className="login-wrapper">
          <h2>הרשמה</h2>
          <p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                  required
                  id="outlined-required"
                  label='אימייל'
                  onChange={e => setEmail(e.target.value)} 
              />
            </LocalizationProvider>
          </p>
          <p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                  required
                  id="outlined-required"
                  label='שם פרטי'
                  onChange={e => setFirstName(e.target.value)} 
              />
            </LocalizationProvider>
          </p>
          <p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                  required
                  id="outlined-required"
                  label='שם משפחה'
                  onChange={e => setLastName(e.target.value)} 
              />
            </LocalizationProvider>
          </p>
          <p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                  required
                  id="outlined-required"
                  label='שם משרד עריכת דין'
                  onChange={e => setLawOfficeName(e.target.value)} 
              />
            </LocalizationProvider>
          </p>
          <p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                  required
                  label="סיסמה"
                  type="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
              />
            </LocalizationProvider>
          </p>
          <p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                  required
                  label="אימות סיסמה"
                  type="password"
                  autoComplete="current-password"
                  onChange={e => setPasswordConfirmation(e.target.value)}
              />
            </LocalizationProvider>
          </p>
          <div className='loginButtonContainer'>
            <Button variant="contained" onClick={handleSubmit}>הרשם</Button>
          </div>
          <p>
            <span>יש לך משתמש? <a href='/login'>התחבר\י</a></span>
          </p>
        </div>
        <Snackbar open={showregistrationsuccessalert} autoHideDuration={2000} onClose={() => { setRegistrationSuccessOpen(false); }}>
          <Alert variant="filled" severity="success" sx={{ width: '100%' }}>ברוכים הבאים! מעבירים אתכם לסימולטור</Alert>
        </Snackbar>
        <Snackbar open={showregistrationfailurealert} autoHideDuration={3000} onClose={ () => { setRegistrationFailureOpen(false); } }>
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>בעיה בהרשמה, אנא בדקו את הפרטים</Alert>
        </Snackbar>
        <Snackbar open={showinvalidpasswordlengthalert} autoHideDuration={3000} onClose={ () => { setInvalidPasswordLengthAlertOpen(false); } }>
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>אורך הסיסמה צריך להיות לפחות 6 תווים</Alert>
        </Snackbar>
        <Snackbar open={showinvalidpasswordconfirmationalert} autoHideDuration={3000} onClose={ () => { setInvalidPasswordConfirmationAlertOpen(false); } }>
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>סיסמה ואימות הסיסמה אינם זהים</Alert>
        </Snackbar>
        </ThemeProvider>
      </CacheProvider>
    )
  }

  Registration.propTypes = {
    setToken: PropTypes.func.isRequired
  }