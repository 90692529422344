import React, { useState } from 'react';
import './UpsertBillableHours.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';

const theme = createTheme({
    direction: 'rtl'
});
  
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const handleSubmit = async e => {
    alert("TODO: Send the data!");
}
  
export default function UpseUpsertBillableHoursrt({ getToken }) {
    const [case_name, setCaseName] = useState('');
    const [billable_hours_date, setBillableHoursDate] = useState();
    const [employee_name, setEmployeeName] = useState();
    const [billable_hours_description, setBillableHoursDescription] = useState();
    const [billable_hours_amount, setBillableHoursAmount] = useState();
    
    return (
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
          <div className="login-wrapper">
            <h2>הזנת שעות לקוח</h2>
            <p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl  sx={{ minWidth: 210 }}>
                        <InputLabel id="case-name-label">בחר\י תיק</InputLabel>
                        <Select
                            labelId="case-name-label"
                            id="case-name-select"
                            value={case_name}
                            label="בחר\י תיק"
                            onChange={ event =>  setCaseName(event) }
                            >
                            <MenuItem value={10}>תיק 1</MenuItem>
                            <MenuItem value={20}>תיק 2</MenuItem>
                            <MenuItem value={30}>תיק 3</MenuItem>
                        </Select>
                    </FormControl>
                </LocalizationProvider>
            </p>
            <p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker className='datePicker'
                            label="תאריך"
                            name="billable_hours_date" 
                            value={billable_hours_date} 
                            // onChange={ event => setLastPleadingDate(event.toISOString()) } 
                            onChange={ event => setBillableHoursDate(event) } 
                            renderInput={(params) => <TextField {...params} />} 
                            />
              </LocalizationProvider>
            </p>
            <p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextField
                    required
                    id="outlined-required"
                    label='שם עו"ד'
                    onChange={e => setEmployeeName(e.target.value)} 
                />
              </LocalizationProvider>
            </p>
            <p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextField
                    required
                    id="outlined-required"
                    label='תיאור'
                    onChange={e => setBillableHoursDescription(e.target.value)} 
                />
              </LocalizationProvider>
            </p>
            <p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextField
                    required
                    id="outlined-required"
                    label='שעות'
                    onChange={e => setBillableHoursAmount(e.target.value)} 
                />
              </LocalizationProvider>
            </p>
            <div className='loginButtonContainer'>
              <Button variant="contained" onClick={handleSubmit}>הזן\י שעות</Button>
            </div>
          </div>
          </ThemeProvider>
        </CacheProvider>
      )
}