import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import EventSimulator from './components/EventSimulator';
import Login from './components/Login';
import Registration from './components/Registration';
import UpsertBillableHours from './components/BillableHours/Upsert';
import useToken from './components/App/useToken';
import Test from './components/Test';
import ReactGA from 'react-ga';

//react router: https://www.robinwieruch.de/react-router-private-routes/#:~:text=Private%20Routes%20in%20React%20Router,page%2C%20they%20cannot%20access%20it

export default function App() {

  const { token, setToken, getToken } = useToken();
  //const navigate = useNavigate();
  // if(!token) {
  //   return <Login setToken={setToken} />
  // }

  //ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="wrapper">
      {/* <h1>--</h1> */}
      <BrowserRouter>
        <Routes>
          {/* <Route element={<RequireAuth token={ token } setToken={ setToken } />}> */}
            <Route path="/" element={<EventSimulator getToken={ getToken }/>} />
            <Route path="/eventsimulator" element={ <EventSimulator getToken={ getToken }/> } />
            {/* <Route path="/billablehours/update" element={<UpsertBillableHours setToken={ getToken } />} /> */}
          {/* </Route> */}
          <Route path="/login" element={<Login setToken={ setToken }/>} />
          <Route path="/registration" element={<Registration setToken={ setToken } />} />
          <Route path="/test" element={<Test setToken={ setToken } />} />
        </Routes>
      </BrowserRouter>
    </div>
  ); 
}

function RequireAuth({ token, setToken, children, }) {
  // let auth = useAuth();
  //let location = useLocation();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return children ? children : <Outlet />;
}

//export default App;